@charset "utf-8";
@use "sass:meta";

@import url('https://fonts.cdnfonts.com/css/verdana');

$esbjerg-color: #acc7e4;

$link: "green";
$family-primary: 'Verdana';
$primary: red;

// Bulma
@import "../node_modules/bulma/sass/utilities/_all.sass";
@import "../node_modules/bulma/sass/base/_all.sass";
@import "../node_modules/bulma/sass/elements/button.sass";
@import "../node_modules/bulma/sass/elements/container.sass";
@import "../node_modules/bulma/sass/elements/title.sass";
@import "../node_modules/bulma/sass/form/_all.sass";
@import "../node_modules/bulma/sass/components/navbar.sass";
@import "../node_modules/bulma/sass/layout/hero.sass";
@import "../node_modules/bulma/sass/layout/section.sass";

.container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 3em;
}

/* Section Playground */

$section-base-color: grey;

.section:nth-child(1) {
  background-color: #acc7e4;
}


.section:nth-child(2) {
  background-color: rgba($section-base-color, 0.2);
}

.section:nth-child(3) {
  background-color: rgba($section-base-color, 0.18);
}

.section:nth-child(4) {
  background-color: rgba($section-base-color, 0.15);
}

.section:nth-child(5) {
  background-color: rgba($section-base-color, 0.1);
}

.esbjerg-logo {
  margin-bottom: 20px;
}
