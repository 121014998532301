@charset "utf-8";

@import url('https://fonts.cdnfonts.com/css/verdana');

$family-primary: 'Verdana';

$esbjerg-light-blue: #7fb6e0;
$link: darken($esbjerg-light-blue, 30);
$footer-background-color: lighten($esbjerg-light-blue, 10);

@import "../../../node_modules/bulma/sass/utilities/_all.sass";
@import "../../../node_modules/bulma/sass/base/_all.sass";
@import "../../../node_modules/bulma/sass/elements/button.sass";
@import "../../../node_modules/bulma/sass/elements/container.sass";
@import "../../../node_modules/bulma/sass/elements/title.sass";

@import "../../../node_modules/bulma/sass/components/navbar.sass";
@import "../../../node_modules/bulma/sass/components/card.sass";

@import "../../../node_modules/bulma/sass/layout/hero.sass";
@import "../../../node_modules/bulma/sass/layout/section.sass";
@import "../../../node_modules/bulma/sass/layout/footer.sass";

@import "../../../node_modules/bulma/sass/grid/columns.sass";

h1.title {
  font-size: 40px;
  margin-bottom: 0!important;
}


h2 {
  font-size: 24px;
}

@include  mobile{
  h1.title {
    font-size: 25px;
  }
  h2 {
    font-size: 20px;
  }
}

h3 {
  font-weight: bold;
  margin-top: 50px;
  margin-bottom: 10px;
  font-size: 20px;
}

.geodataportal {
  opacity: 0.8;
  // @include center;
}

.geodataportal:hover {
  opacity: 1;
  cursor: pointer;
}

.container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  grid-gap: 3em;
}

.abstract {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 20px;
}

p {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 15px;
}

a:hover {
  color: black;
  text-underline-mode: bulmaDarken($esbjerg-light-blue, 100);
}

.topic {
  padding-bottom: 10px;
}

.bulma-overlay-mixin-parent {
  background-image: image-set(url("./Nordiq.png") 1x);
  background-size: cover;
  border-radius: 0.5em;
  position: relative;
  height: 45.25rem;
  width: 55rem;
  margin-top: 25px;
}

@include mobile {
  .bulma-overlay-mixin-parent {
    width: 44rem;
    height: 32rem;
  }
}

@media only screen and (max-width: 390px) {
  .bulma-overlay-mixin-parent {
    width: 310px;
    height: 260px;
  }
}

@media only screen and (max-width: 321px) {
  .bulma-overlay-mixin-parent {
    width: 240px;
    height: 200px;
  }
}

.bulma-overlay-mixin {
  @include overlay(1.5rem);
  background-color: $esbjerg-light-blue;
  border-radius: 0.25em;
  color: white;
  padding: 1em;
  text-align: center;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
}

.bulma-overlay-mixin:hover {
  opacity: 0.9;
  cursor: pointer;
}

.portrait {
  width: auto;
  height: auto;
  max-width: 180px;
  max-height: 180px;
  border-radius: 50%;
}

.mapstore-hero-headline {
  font-size: 50px;
}

section.contact h2 {
  margin-bottom: 50px;
}
